import React, { useState, useEffect, useContext, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  RiCloseFill,
  RiAddLine,
  RiPencilLine,
  RiSubtractLine,
} from "react-icons/ri";
import { Progress } from "@material-tailwind/react";
import ReactSelect from "react-select";
import { Spinner } from "reactstrap";
import AlertContext from "../../context/AlertContext/allertContext";
import {
  customStyles,
  toogleOverflow,
} from "../../utils/helpers/style_helpers";
import Monument from "../../models/Monument";
import City from "../../models/City";
import { updateFile, uploadFile } from "../../api/fileService";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import MapComponent from "../Map";

const steps = ["Informations générales", "Informations supplémentaires"];

export default function Component({
  setShowFormular,
  data,
  setSelectedMonument,
  isConsulting,
}) {
  const { showAlert } = useContext(AlertContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(undefined);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [progress, setProgress] = useState(0);
  const [coordinates, setCoordinates] = useState(
    data?.coordinates || { lat: 48.864716, lng: 2.349014 }
  );
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [iconPreview, setIconPreview] = useState("");
  const filePondRef = useRef(null);
  const iconUrlRef = useRef(null);
  //MONUMENT-FORM
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      description: data?.description || "",
      thumbnailImage: data?.images || [""],
      thumbnailVideo: data?.thumbnailVideo || "",
      AdditionalVideos: data?.AdditionalVideos?.filter(
        (video) => video.title || video.url
      ) || [{ title: "", url: "" }],
      icon: data?.iconUrl || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter monument name"), // required
      description: Yup.string().required("Please Enter monument description"), // required
      // duration: Yup.number().positive().required("Please Enter visit duration"), // required
      thumbnailImage: Yup.string().required("Please Upload a monument image"), // required
      icon: Yup.mixed().required("Map Icon is required"), // required
      thumbnailVideo: Yup.string()
        .url("Must be a valid URL")
        .required("Video is required"), // required
      AdditionalVideos: Yup.array().of(
        Yup.object().shape({
          title: Yup.string(),
          url: Yup.string().url("Must be a valid URL"),
        })
      ),
      // SourceData: Yup.array().of(
      //   Yup.object().shape({
      //     title: Yup.string(),
      //     url: Yup.string().url("Must be a valid URL"),
      //   })
      // ),
    }),
    onSubmit: async (values) => {
      if (loading) return;
      setLoading(true);

      try {
        if (!selectedCity) {
          showAlert("Please select a city.");
          return;
        }

        showAlert("Preparing monument data...", "info");
        setProgress(10);

        const monumentData = {
          name: values.name,
          cityID: selectedCity.value,
          coordinates: coordinates,
          description: values.description,
          thumbnailVideo: values.thumbnailVideo,
          AdditionalVideos: values.AdditionalVideos.filter(
            (video) => video.title || video.url
          ),
          images: data?.images || [],
          iconUrl: values.icon,
        };

        showAlert("Handling files...", "info");

        // Handle thumbnail image
        if (thumbnailFile && thumbnailFile !== data?.images?.[0]) {
          try {
            let imageUrl;
            if (data) {
              // Updating existing monument
              imageUrl = await updateFile(
                thumbnailFile,
                data.images?.[0],
                "monuments",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(10 + progressPercentage * 0.4);
                }
              );
            } else {
              // Creating new monument
              imageUrl = await uploadFile(
                thumbnailFile,
                "monuments",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(10 + progressPercentage * 0.4);
                }
              );
            }
            if (imageUrl) {
              monumentData.images = [imageUrl];
            }
          } catch (e) {
            console.log(e);
            showAlert(
              `Error handling image ${data ? "update" : "upload"}`,
              "error"
            );
            setLoading(false);
            return;
          }
        }

        // Handle icon file
        if (iconFile && iconFile !== data?.iconUrl) {
          try {
            let iconUrl;
            if (data) {
              // Updating existing monument
              iconUrl = await updateFile(
                iconFile,
                data.iconUrl,
                "markers",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(60 + progressPercentage * 0.3);
                }
              );
            } else {
              // Creating new monument
              iconUrl = await uploadFile(
                iconFile,
                "markers",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(60 + progressPercentage * 0.3);
                }
              );
            }
            if (iconUrl) {
              monumentData.iconUrl = iconUrl;
            }
          } catch (e) {
            console.log(e);
            showAlert(
              `Error ${data ? "updating" : "uploading"} map icon`,
              "error"
            );
            setLoading(false);
            return;
          }
        }

        setProgress(85);
        showAlert(
          data ? "Updating monument..." : "Creating monument...",
          "info"
        );

        if (data) {
          await Monument.update(data.id, monumentData);
        } else {
          await Monument.create(monumentData);
        }

        setProgress(100);
        showAlert(
          data
            ? "Monument updated successfully."
            : "Monument created successfully.",
          "success"
        );
        setShowFormular(false);
        setSelectedMonument(undefined);
      } catch (error) {
        console.error("Error in form submission:", error);
        showAlert("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });
  /**********************************************************/
  // FETCH DATA FROM FIREBASE , INCLUDING THE IMAGES
  console.log({ validation });
  useEffect(() => {
    loadCities();
    if (data) {
      setSelectedCity(cities.find((city) => city.value === data.cityID));
      if (data.images && data.images.length > 0) {
        fetchImageFromFirebase(data.images[0], false);
        setThumbnailFile(data.images[0]);
      }
      if (data.iconUrl) {
        fetchImageFromFirebase(data.iconUrl, true);
        setIconFile(data.iconUrl);
      }
    }
    toogleOverflow();
    return () => toogleOverflow();
  }, [data]);

  useEffect(() => {
    if (cities.length > 0 && data?.cityID) {
      const foundCity = cities.find((city) => city.value === data?.cityID);
      setSelectedCity(foundCity);
    }
  }, [cities, data?.cityID]);

  const loadCities = async () => {
    const querySnapshot = await City.getAllC();
    const cityData = querySnapshot.docs.map((doc) => ({
      value: doc.id,
      label: doc.data().name,
    }));
    setCities(cityData);

    if (data?.cityID) {
      const foundCity = cityData.find((city) => city.value === data?.cityID);
      setSelectedCity(foundCity);
    }
  };

  const fetchImageFromFirebase = async (imageUrl, isIcon = false) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, imageUrl);
      const url = await getDownloadURL(imageRef);
      if (isIcon) {
        validation.setFieldValue("icon", url);
        setIconPreview(url);
      } else {
        validation.setFieldValue("thumbnailImage", url);
        setThumbnailPreview(url);
      }
    } catch (error) {
      console.error("Error fetching image from Firebase:", error);
      if (error.code === "storage/unauthorized") {
        showAlert(
          "Permission denied. Please check your Firebase permissions.",
          "error"
        );
      } else {
        showAlert(`Error loading image: ${error.message}`, "error");
      }
    }
  };
  /**********************************************************/
  // RENDER FORM INPUTS
  const renderStep = () => {
    if (isConsulting) {
      return (
        <>
          <div className="flex gap-4 items-center  justify-evenly">
            {/* MONUMENT NAME */}
            <div className="mb-3 w-full md:w-1/2">
              <label className="text-gray-800 block mb-2 font-bold">Nom</label>
              <p className="text-gray-600 text-sm">{data?.name || "N/A"}</p>
            </div>
            {/* MONUMENT CITY */}
            <div className="mb-3 w-full md:w-1/2">
              <label className="block font-bold mb-2">Ville</label>
              <p className="text-gray-600 text-sm">
                {selectedCity?.label || "N/A"}
              </p>
            </div>
          </div>
          <div className="flex gap-4 items-center justify-evenly">
            {/* DURATION */}
            {/* <div className="mb-3 w-full md:w-1/2">
              <label className="text-gray-800 block mb-2 font-bold">
                Durée <span className="font-bold text-orange-500">(s)</span>
              </label>
              <p className="text-gray-600 text-sm">{data?.duration || "N/A"}</p>
            </div> */}
            {/* COORDINATES */}
            <div className="mb-3  w-full md:w-1/2">
              <label className="text-gray-800 block mb-0.5 font-bold">
                Coordinates
              </label>
              <p className="text-gray-600 text-sm">
                Latitude: {data?.coordinates?.lat || "N/A"}, Longitude:{" "}
                {data?.coordinates?.lng || "N/A"}
              </p>
            </div>
          </div>
          {/* THUMBNAIL PREVIEW */}
          <div className="w-full mb-3">
            <label className="text-gray-800 block mb-2 font-bold">Image</label>
            {thumbnailPreview && (
              <img
                src={thumbnailPreview}
                alt="Monument Thumbnail"
                className="w-full h-64 object-cover rounded-lg"
              />
            )}
          </div>
          {/* DESCRIPTION */}
          <div className="mb-3 pt-1">
            <label className="text-gray-800 block  mb-0.5 font-bold">
              Description
            </label>
            <p className="text-gray-600 text-sm">
              {data?.description || "N/A"}
            </p>
          </div>
          {/* ICON PREVIEW */}
          <div className="mb-3">
            <label className="text-gray-800 block mb-0.5 font-bold">
              Icone
            </label>
            {iconPreview && (
              <img
                src={iconPreview}
                alt="Map Icon"
                className="w-16 object-cover"
              />
            )}
          </div>
          {/* VIDEO THUMBNAIL LINK */}
          <div className="mb-3">
            <label className="text-gray-800 block  mb-0.5 font-bold">
              Lien video
            </label>
            <a
              href={data?.thumbnailVideo}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline text-sm"
            >
              {data?.thumbnailVideo || "N/A"}
            </a>
          </div>
          {/* ADDITIONAL VIDEOS LINK */}
          <div className="mb-3">
            <label className="text-gray-800 block  mb-0.5 font-bold">
              Videos supplémentaires
            </label>
            {data?.AdditionalVideos?.map((video, index) => (
              <div key={index} className=" mb-0.5 flex gap-8 text-sm">
                <p className="font-semibold">{video.title || "N/A"}</p>
                <a
                  href={video.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {video.url || "N/A"}
                </a>
              </div>
            ))}
          </div>
          {/* ADDITIONAL SOURCES LINK */}
          {/*
          <div className="mb-3">
            <label className="text-gray-800 block  mb-0.5 font-bold">
              Sources
            </label>
            {data?.SourceData?.map((source, index) => (
              <div key={index} className=" mb-0.5 flex gap-8 text-sm">
                <p className="font-semibold">{source.title || "N/A"}</p>
                <a
                  href={source.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {source.url || "N/A"}
                </a>
              </div>
            ))}
          </div>
          */}
        </>
      );
    } else {
      switch (currentStep) {
        case 0:
          return (
            <>
              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Nom <span className="text-red-500">*</span>
                </label>
                <input
                  name="name"
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                  placeholder="Entrer le nom"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                />
                {validation.touched.name && validation.errors.name ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.name}
                  </p>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="block font-bold mb-3" htmlFor="city">
                  Ville <span className="text-red-500">*</span>
                </label>
                <ReactSelect
                  value={selectedCity}
                  isMulti={false}
                  options={cities}
                  onChange={(val) => setSelectedCity(val)}
                  menuShouldScrollIntoView={true}
                  maxMenuHeight={200}
                  className="!rounded-full"
                  styles={customStyles}
                />
              </div>
              {/* <div className="mb-3">
                <label
                  htmlFor="duration"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Durée <span className="font-bold text-orange-500">(s)</span>{" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  name="duration"
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                  placeholder="Entrer la durée"
                  type="number"
                  min={0}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.duration}
                />
                {validation.touched.duration && validation.errors.duration ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.duration}
                  </p>
                ) : null}
              </div> */}
              <div className="w-full mb-3 ">
                <label
                  htmlFor="thumbnail"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Image <span className="text-red-500">*</span>
                </label>

                <div className="relative  ">
                  {(data?.images?.length > 0 || thumbnailPreview) && (
                    <label
                      htmlFor="thumbnail"
                      className="cursor-pointer absolute bg-orange-500 rounded-full p-1.5 -right-2 -top-2 "
                    >
                      <RiPencilLine className="text-white" size={20} />
                    </label>
                  )}
                  <input
                    ref={filePondRef}
                    type="file"
                    name="thumbnail"
                    id="thumbnail"
                    accept="image/*"
                    onChange={(e) => handleThumbnailChange(e)}
                    className={`w-full border-2 text-gray-500 ${
                      data?.images?.length > 0 || thumbnailPreview
                        ? "hidden"
                        : "inline"
                    } outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full`}
                  />
                  {thumbnailPreview && (
                    <img
                      src={thumbnailPreview}
                      alt="Thumbnail Preview"
                      className=" w-full h-32 object-cover"
                    />
                  )}
                </div>
                {validation.touched.thumbnailImage &&
                validation.errors.thumbnailImage ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.thumbnailImage}
                  </p>
                ) : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="description"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Description <span className="text-red-500">*</span>
                </label>
                <textarea
                  name="description"
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                  placeholder="Entrer la description"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.description}
                  </p>
                ) : null}
              </div>
              <div className="">
                <label className="text-gray-800 block mb-2 font-bold">
                  Coordinates
                </label>
                <div className="flex flex-col md:flex-row gap-4">
                  <input
                    className="w-full md:w-1/2 border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                    placeholder="Latitude"
                    type="number"
                    value={coordinates.lat}
                    onChange={(e) =>
                      setCoordinates({
                        ...coordinates,
                        lat: parseFloat(e.target.value),
                      })
                    }
                  />
                  <input
                    className="w-full md:w-1/2 border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                    placeholder="Longitude"
                    type="number"
                    value={coordinates.lng}
                    onChange={(e) =>
                      setCoordinates({
                        ...coordinates,
                        lng: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>
                <h1
                  className="mt-3 bg-gray-100 p-2 text-center hover:bg-gray-200 rounded-xl w-full cursor-pointer"
                  onClick={() => setShowMap(true)}
                >
                  selectionner la position
                </h1>
              </div>
            </>
          );
        case 1:
          return (
            <>
              <div className="mb-3">
                <label
                  htmlFor="thumbnailVideo"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Lien video <span className="text-red-500">*</span>
                </label>
                <input
                  name="thumbnailVideo"
                  className="w-full border-2 text-gray-500 text-sm outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                  placeholder="URL de la video"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.thumbnailVideo || ""}
                />
                {validation.touched.thumbnailVideo &&
                validation.errors.thumbnailVideo ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.thumbnailVideo}
                  </p>
                ) : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="AdditionalVideos"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Videos supplémentaires
                </label>
                {validation.values.AdditionalVideos.map((video, index) => (
                  <div
                    key={index}
                    className="relative flex flex-col gap-4 mb-2"
                  >
                    <div className="flex items-start gap-2">
                      <div className="flex-grow">
                        <textarea
                          name={`AdditionalVideos.${index}.title`}
                          className="w-full border text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                          placeholder="Titre de la video"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={video.title}
                        />
                        <input
                          name={`AdditionalVideos.${index}.url`}
                          className="w-full mt-4 border-2 text-sm text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                          placeholder="URL de la video"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={video.url}
                        />
                      </div>
                      {validation.values.AdditionalVideos.length > 1 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveVideo(index)}
                          className="p-2 bg-red-100 text-red-600 rounded-full hover:bg-red-200"
                        >
                          <RiSubtractLine size={20} />
                        </button>
                      )}
                    </div>
                    {index < validation.values.AdditionalVideos.length - 1 && (
                      <div className="relative my-2">
                        <div
                          className="absolute inset-0 flex items-center px-4"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300 border-dashed" />
                        </div>
                        <div className="relative flex justify-center">
                          <div className="bg-white px-2">
                            <div className="h-1 w-8 rounded-full bg-gray-100" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="flex w-full justify-end">
                  <button
                    type="button"
                    onClick={() =>
                      validation.setFieldValue("AdditionalVideos", [
                        ...validation.values.AdditionalVideos,
                        { title: "", url: "" },
                      ])
                    }
                    disabled={!canAddNewVideo()}
                    className={`mt-2   flex items-center gap-2 p-2  rounded-full  ${
                      canAddNewVideo()
                        ? "bg-green-100 text-green-700 hover:bg-green-300"
                        : "bg-gray-100 text-gray-400 cursor-not-allowed"
                    }`}
                  >
                    <RiAddLine className="inline" size={20} />
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="iconUrl"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Icone <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  {(data?.iconUrl || iconPreview) && (
                    <label
                      htmlFor="icon"
                      className="cursor-pointer absolute bg-orange-500 rounded-full p-1.5 -right-2 -top-2 "
                    >
                      <RiPencilLine className="text-white" size={20} />
                    </label>
                  )}

                  <input
                    ref={iconUrlRef}
                    type="file"
                    name="icon"
                    id="icon"
                    accept="image/*"
                    onChange={(e) => handleIconChange(e)}
                    className={`w-full border-2 text-gray-500 ${
                      data?.iconUrl || iconPreview ? "hidden" : "inline"
                    } outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full`}
                  />
                  {console.log({iconPreview})}
                  {iconPreview && (
                    <img
                      src={iconPreview}
                      className="mt-2 w-full h-32 object-contain"
                    />
                  )}
                </div>
                {validation.touched.icon && validation.errors.icon ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.icon}
                  </p>
                ) : null}
                {/* {validation.values.icon && (
                  <img
                    src={validation.values.icon}
                    alt="Map Icon Preview"
                    className="mt-2 w-16 h-16 object-cover"
                  />
                )} */}
              </div>
              {/* <div className="mb-3">
                <label
                  htmlFor="SourceData"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Sources
                </label>
                {validation.values.SourceData.map((source, index) => (
                  <div key={index}>
                    <div className="flex flex-col gap-4 my-2">
                      <textarea
                        onChange={validation.handleChange}
                        name={`SourceData.${index}.title`}
                        placeholder="Titre"
                        onBlur={validation.handleBlur}
                        value={source.title}
                        className="w-full border text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                      />
                      <input
                        name={`SourceData.${index}.url`}
                        className="w-full border text-sm text-gray-500 outline-none border-gray200 bg-gray-50 py-2 pl-4 rounded-full"
                        placeholder="Source URL"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={source.url}
                      />
                    </div>
                    {index < validation.values.SourceData.length - 1 && (
                      <div className="relative my-4 ">
                        <div
                          className="absolute inset-0 flex items-center px-4"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300 border-dashed" />
                        </div>
                        <div className="relative flex justify-center">
                          <div className="bg-white px-2">
                            <div className="h-1 w-8 rounded-full bg-gray-100" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="w-full flex justify-end">
                  <button
                    type="button"
                    onClick={() =>
                      validation.setFieldValue("SourceData", [
                        ...validation.values.SourceData,
                        { title: "", url: "" },
                      ])
                    }
                    className="mt-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300"
                  >
                    <RiAddLine className="inline mr-2" /> Ajouter une Source
                  </button>
                </div>
              </div> */}
            </>
          );
        default:
          return null;
      }
    }
  };

  const canAddNewVideo = () => {
    const videos = validation.values.AdditionalVideos;
    // Check if the last video entry has both title and url filled
    const lastVideo = videos[videos.length - 1];
    return lastVideo?.title?.trim() !== "" && lastVideo?.url.trim() !== "";
  };

  const handleRemoveVideo = (index) => {
    const newVideos = [...validation.values.AdditionalVideos];
    newVideos.splice(index, 1);
    // If there are no videos left, add an empty one
    if (newVideos.length === 0) {
      newVideos.push({ title: "", url: "" });
    }
    validation.setFieldValue("AdditionalVideos", newVideos);
  };

  /**********************************************************/
  // HANDLE THUMBNAILS UPLOAD

  const handleThumbnailChange = (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setThumbnailFile(selectedFile);

      // Create a local URL for the image file
      const previewUrl = URL.createObjectURL(selectedFile);
      setThumbnailPreview(previewUrl);

      // Assuming validation is a formik or similar object
      validation.setFieldValue("thumbnailImage", selectedFile.name);
    } else {
      setThumbnailFile(null);
      setThumbnailPreview(null); // Clear the preview if no file is selected
      validation.setFieldValue("thumbnailImage", "");
    }
  };

  const handleIconChange = (event) => {
    if (event.target.files.length > 0) {
      setIconFile(event.target.files[0]);

      // Create a local URL for the image file
      const previewUrl = URL.createObjectURL(event.target.files[0]);
      setIconPreview(previewUrl);

      validation.setFieldValue("icon", event.target.files[0].name);
    } else {
      setIconFile(null);
      validation.setFieldValue("icon", "");
    }
  };
  /**********************************************************/
  // HANDLE STEPS NAVIGATIONS
  const handleNext = () => {
    setCurrentStep((prev) => Math.min(prev + 1));
    setProgress((prev) => Math.min(prev + 50, 100)); // Assuming 2 steps, adjust as needed
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
    setProgress((prev) => Math.max(prev - 50, 0)); // Assuming 2 steps, adjust as needed
  };
  /********************************************************* */

  const handleMapClick = (data) => {
    setCoordinates({
      lng: data.latLng.lng(),
      lat: data.latLng.lat(),
    });
  };
  return (
    <form onSubmit={validation.handleSubmit}>
      {showMap && (
        <MapComponent
          lat={coordinates.lat}
          lng={coordinates.lng} // Make sure it's lng here
          setLat={(newLat) =>
            setCoordinates((prev) => ({ ...prev, lat: newLat }))
          }
          setLng={(newLng) =>
            setCoordinates((prev) => ({ ...prev, lng: newLng }))
          }
          setShowMap={setShowMap}
          onClick={handleMapClick}
            needLocation={false}
        />
      )}
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        <div className="flex relative items-center overflow-hidden justify-center h-full text-center sm:block">
          {loading && (
            <div className="absolute w-full h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner
                children={"Chargement..."}
                className="h-10 w-10 text-main self-center"
              />
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>

          <div
            className="inline-block align-center  overflow-y-auto max-h-[calc(100%-4rem)] bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white pt-5 pb-4 px-4 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  {data && !isConsulting
                    ? "Modification d'un monument"
                    : data && isConsulting
                    ? "Consultation d'un monument"
                    : "Ajout d'un monument"}
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setSelectedMonument(undefined);
                    setShowFormular(false);
                  }}
                >
                  <RiCloseFill className="w-6 h-6" />
                </div>
              </div>

              {!isConsulting && (
                <div className="mb-4 flex justify-center gap-1 items-center">
                  {steps.map((step, index) => (
                    <button
                      key={step}
                      type="button"
                      onClick={() => setCurrentStep(index)}
                      className={`w-1/2 py-1 rounded-2xl ${
                        currentStep === index
                          ? "bg-orange-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                    >
                      {step}
                    </button>
                  ))}
                </div>
              )}

              {renderStep()}
            </div>
            <div className="w-full">
              <Progress
                className="h-2"
                color="amber"
                value={progress}
                variant="filled"
              />
            </div>
            {!isConsulting && (
              <div className="bg-gray-50 px-4 py-3 text-center">
                <button
                  type="button"
                  onClick={handlePrevious}
                  disabled={currentStep === 0}
                  className="py-2 px-4 bg-gray-500 text-white disabled:bg-gray-200 font-semibold text-sm rounded-xl hover:bg-gray-700 mr-2"
                >
                  Précédent
                </button>
                {currentStep < steps.length - 1 ? (
                  <div
                    type="button"
                    onClick={handleNext}
                    className="py-2 px-4 bg-orange-400 inline cursor-pointer text-white font-semibold text-sm rounded-xl hover:bg-orange-500"
                  >
                    Suivant
                  </div>
                ) : (
                  <button
                    color="success"
                    disabled={loading}
                    className="py-2 px-4 bg-orange-400 text-white font-semibold text-sm rounded-xl hover:bg-orange-500"
                    type="submit"
                  >
                    {loading ? (
                      <Spinner size="sm" />
                    ) : data ? (
                      "Mettre à jour"
                    ) : (
                      "Créer"
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
